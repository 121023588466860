<template>
  <div class="block__flex block__align-center block__column" style="position: relative;">
    <div
      style="width: 100%"
      :class="`input-i5 ${setFocus()}`"
    >
      <label :for="name" @click="handleFocusOut">{{label}}
        <span v-if="required" >*</span>
        <span v-if="subLabel && !error" class="input-i5__sublabel">
          {{subLabel}}
        </span>
        <span v-if="error" class="input-i5__error">
          {{error}}
        </span>
      </label>
      <div style="position: relative;">
        <input
          :tabindex="ti"
          :class="`${error ? 'input-i5_error' : ''}`"
          @focus="handleFocus"
          @blur="handleFocusOut"
          :value="value"
          :id="name"
          :name="name"
          autocomplete="off"
          readonly
          :disabled="disabled === true"
          :style="`${clearable ? 'padding-right: 20px;' : ''} height: ${
            height}px !important; margin-bottom: ${
            marginBottom}px !important; ${maxWidth ? `max-width: ${maxWidth}px !important;` : ''} ${
            showArrow ? 'padding-right: 40px;' : ''}`"
          @input="handleChange">
        <ClearImage v-if="clearable && value" class="input-i5__clear" @click="clear" />
        <div
          @click="handleFocusOut"
          class="input-i5__select_arrow"
          v-if="showArrow"
          :style="`height: ${height - 4}px; ${!open
          ? 'pointer-events: none;'
          : ''} border-radius: 0 3px 3px 0;`">
          <div
            class="block__flex block__center block__align-center"
            :style="`${!open
          ? 'transition: transform .3s linear; transform: rotate(0);'
          : 'transform: rotate(180deg); transition: transform .3s linear;'}`">
            <Arrow />
          </div>
        </div>
      </div>
    </div>
    <div
      :id="`${name}_dropdown`"
      :style="{
        width: `${elWidth}px`,
        maxHeight: `${dropDownMaxHeight}px`,
        marginTop: `${elHeight + 2}px`,
        position: setPosition()
      }"
      :class="`search__container ${setOpen()} ${setDirection()}`">
      <div
        v-for="(val, index) in dropdownValues"
        v-bind:key="`dd_${index}`"
        @mousedown="(e) => e.preventDefault()">
        <div
          @click="setElement(val)"
          class="search__item block block__align-center">
          <img
            class="component__margin-right"
            v-if="dropdownProps && dropdownProps.img && val[dropdownProps.img]"
            :src="val[dropdownProps.img]"
          />
          <div>{{val.label}}</div>
          <div
            v-if="dropdownProps && dropdownProps.sublabel && val[dropdownProps.sublabel]"
            class="component__margin-left">({{val[dropdownProps.sublabel]}})</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClearImage from '@/assets/pictures/form/clear.svg';
import Arrow from '@/assets/pictures/select/select-arrow2.svg';

export default {
  name: 'Select',
  data() {
    return {
      focus: false,
      open: false,
      elWidth: null,
      dropDownMaxHeight: 100,
      dropDownDirection: 'bottom', // top
      dropdownTransform: 0,
      value: '',
      dropdownValues: [],
    };
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [Object, String],
      default: null,
    },
    name: {
      type: String,
      default: 'search',
    },
    defaultValue: {
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
    },
    placement: {
      type: String,
      validator: function (value) {
        return [
          'bottomLeft',
          'bottom',
          'bottomRight',
        ].indexOf(value) !== -1;
      },
    },
    positionType: {
      type: String,
      default: 'scrollable', // fixed
    },
    active: [Number, String, Object],
    required: {
      type: Boolean,
      default: false,
    },
    ti: {
      type: Number,
      default: 0,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dropdownProps: {
      type: Object, // label, value, img, sublabel
    },
    height: {
      type: Number,
      default: 35,
    },
    marginBottom: {
      type: Number,
      default: 15,
    },
    maxWidth: {
      type: Number,
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    active: function () {
      this.open = false;
    },
    defaultValue: function (val) {
      this.value = val;
    },
    values: function (val) {
      this.setDropdownValues(val);
    },
  },
  computed: {
    elHeight() {
      return this.label ? this.height + 35 : this.height + 3;
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    },
    handleFocus() {
      this.open = true;
    },
    handleFocusOut() {
      this.open = false;
    },
    setFocus() {
      if (this.focus) {
        return 'search__focus';
      }
      return '';
    },
    setOpen() {
      let result = '';
      if (this.open) {
        result = 'search__open';
        this.$nextTick(() => {
          const dropdown = document.getElementById(`${this.name}_dropdown`);
          const dropdownWidth = dropdown.offsetWidth;
          const x = dropdown.getBoundingClientRect().x;
          const width = window.innerWidth;
          if (width / 2 < x && x + dropdownWidth > width) {
            this.dropdownTransform = `-${Math.round((x + dropdownWidth - width) + 8)}px`;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          } else if (this.placement === 'bottomLeft') {
            this.dropdownTransform = this.$el.getBoundingClientRect().x - x;
            dropdown.style.transform = `translate(${this.dropdownTransform}, 0)`;
          }
          this.dropdownToggle(dropdown);
        });
      }
      return result;
    },
    dropdownToggle() {
      const y = this.$el.getBoundingClientRect().y;
      this.dropDownDirection = 'bottom';
      this.dropDownMaxHeight = window.innerHeight - y - 90;
      // console.log('bottom', window.innerHeight - y - 30);
    },
    clear() {
      this.value = '';
      this.$emit('clear');
    },
    setDirection() {
      return `search_${this.dropDownDirection}`;
    },
    setPosition() {
      let result = '';
      if (this.positionType === 'scrollable') {
        result = 'absolute !important';
      } else if (this.positionType === 'fixed') {
        result = 'fixed !important';
      }
      return result;
    },
    handleChange(evt) {
      this.value = evt.target.value;
    },
    setDropdownValues(val) {
      const values = [];
      val.forEach((item) => {
        const result = {};
        if (this.dropdownProps.label && item[this.dropdownProps.label]) {
          result.label = item[this.dropdownProps.label];
        }
        if (this.dropdownProps.value && item[this.dropdownProps.value]) {
          result.value = item[this.dropdownProps.value];
        }
        if (this.dropdownProps.img && item[this.dropdownProps.img]) {
          result.img = item[this.dropdownProps.img];
        }
        if (this.dropdownProps.sublabel && item[this.dropdownProps.sublabel]) {
          result.sublabel = item[this.dropdownProps.sublabel];
        }
        values.push(result);
      });
      this.dropdownValues = values;
    },
    setElement(val) {
      this.value = val.label;
      this.$emit('change', val);
      this.open = false;
      document.getElementById(`${this.name}`).blur();
    },
  },
  mounted() {
    this.elWidth = this.$el.scrollWidth;
    this.value = this.defaultValue;
    if (this.values) {
      this.setDropdownValues(this.values);
    }
  },
  components: {
    ClearImage,
    Arrow,
  },
};
</script>
